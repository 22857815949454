import React, { FunctionComponent, useEffect, useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import i18next from 'i18next';

import {
  requiredValidation,
  emailValidation,
  profileSelectField,
  profileCompanyRole,
} from 'helpers/validations';
import countryStates from 'helpers/json/country-states.json';
import RadioGroup from 'components/inputs/RadioGroup';
import Input from 'components/inputs/Input';
import RadioButton from 'components/inputs/RadioButton';
import CustomCheckbox from 'components/inputs/Checkbox';
import Select from 'components/inputs/Select';
import { IProfileFormData } from 'views/ProfileView/types';
import { CompanySectors, companySectors } from 'helpers/enums/companySectors';
import {
  CompanyNumberOfCollaborators,
  companyNumberOfCollaborators,
} from 'helpers/enums/companyNumberOfCollaborators';
import {
  CompanyHowDidYouHearAboutUs,
  companyHowDidYouHearAboutUs,
} from 'helpers/enums/companyHowDidYouHearAboutUs';
import { CompanyRole } from 'views/SignUpView/types';
import { ICountryStateData } from 'types/locality.types';
import { CompanySubSectors, companySubSectors } from 'helpers/enums/companySubSector';

import { IFieldOption, IProfileFormProps } from './types';
import {
  InputsLeft,
  InputsRight,
  InputDiv,
  Form,
  ButtonContainer,
  FormButton,
  RowDiv,
  Text,
  MailsText,
  RadioButtonsContainer,
  FieldLabel,
  SelectDiv,
  CheckboxDiv,
  RadioGroupDiv,
} from './styles';
import './i18n';

const ProfileForm: FunctionComponent<
  IProfileFormProps & InjectedFormProps<IProfileFormData, IProfileFormProps>
> = (props) => {
  const { handleSubmit, initialValues, onSubmit, submitting, isOwner, country } = props;
  const {
    cuit,
    name,
    subscribedToReminder,
    acceptPromotions,
    companyRole,
    specialty,
    collaborators,
    sector,
    hearAboutUs,
    state,
    subSector,
  } = initialValues;
  const [selectedCompanyRole, setSelectedCompanyRole] = useState<number | undefined>(companyRole);
  const [selectedCountryStates, setSelectedCountryStates] = useState<string[]>([]);
  const [subSectorLabels, setSubSectorLabels] = useState<string[]>([]);
  const [selectedSector, setSelectedSector] = useState<string>(sector);
  const [renderSubSectorOther, setRenderSubSectorOther] = useState<boolean>(
    subSector === companySubSectors.find((i) => i.value === CompanySubSectors.OTHER)?.label,
  );

  const extractLabels = (fieldOption: IFieldOption[]) => {
    return fieldOption.map((option) => option.label);
  };

  const hearAboutUsLabels = extractLabels(companyHowDidYouHearAboutUs);
  const [renderHearAboutUsOtherText, setRenderHearAboutUsOtherText] = useState<boolean>(
    hearAboutUs === hearAboutUsLabels[CompanyHowDidYouHearAboutUs.OTHER],
  );

  useEffect(() => {
    const currentCountryStateData = countryStates.find(
      (s: ICountryStateData) => s.country === country,
    );

    if (currentCountryStateData) {
      setSelectedCountryStates(currentCountryStateData.data);
    }
  }, [country]);

  const handleChange = (event: any) => {
    setSelectedCompanyRole(parseInt(event.target.value, 10));
  };

  const handleChangeSubSector = (event: any) => {
    setRenderSubSectorOther(false);

    if (event === 'Otros') {
      setRenderSubSectorOther(true);
    }
  };

  const handleChangeHearAboutUs = (event: any) => {
    setRenderHearAboutUsOtherText(false);
    if (event === 'Otros') {
      setRenderHearAboutUsOtherText(true);
    }
  };

  const sectorLabels = extractLabels(companySectors);
  const numberOfCollaboratorsLabels = extractLabels(companyNumberOfCollaborators);

  useEffect(() => {
    const selectedSectorIndex = companySectors.find((i) => i.label === selectedSector)?.value;
    const labels = extractLabels(
      companySubSectors.filter((companySubSector) =>
        companySubSector.type.includes(selectedSectorIndex as CompanySectors),
      ),
    );

    setSubSectorLabels(labels);
  }, [selectedSector]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} data-testid="profile-form">
      <RowDiv>
        <InputsLeft>
          <InputDiv>
            <Input
              variant="outlined"
              input={{ value: name, onChange: () => {} }}
              meta={{ touched: false }}
              label={i18next.t('PROFILE_FORM:NAME')}
              size="small"
              disabled
            />
          </InputDiv>
          <InputDiv>
            <Input
              variant="outlined"
              input={{ value: cuit, onChange: () => {} }}
              meta={{ touched: false }}
              label={i18next.t('PROFILE_FORM:CUIT')}
              size="small"
              disabled
            />
          </InputDiv>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="email"
              label={i18next.t('PROFILE_FORM:EMAIL')}
              size="small"
              validate={[requiredValidation, emailValidation]}
            />
          </InputDiv>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              name="phone"
              label={i18next.t('PROFILE_FORM:PHONE')}
              size="small"
              validate={[requiredValidation]}
            />
          </InputDiv>
          {isOwner && (
            <>
              <SelectDiv>
                <FieldLabel>{i18next.t('PROFILE_FORM:SELECT_STATE')}</FieldLabel>
                <Field
                  // eslint-disable-next-line
                  component={Select}
                  name="state"
                  validate={[requiredValidation]}
                  options={selectedCountryStates}
                  disabled={state !== ''}
                />
              </SelectDiv>
              <InputDiv>
                <Field
                  component={Input}
                  variant="outlined"
                  name="specialty"
                  label={i18next.t('PROFILE_FORM:SPECIALTY')}
                  size="small"
                  validate={[requiredValidation]}
                  disabled={specialty !== ''}
                />
              </InputDiv>
              <SelectDiv>
                <FieldLabel>{i18next.t('PROFILE_FORM:SECTOR')}</FieldLabel>
                <Field
                  // eslint-disable-next-line
                  component={Select}
                  name="sector"
                  validate={[profileSelectField]}
                  onChange={(newSector: any) => setSelectedSector(newSector)}
                  options={sectorLabels}
                />
              </SelectDiv>
              <SelectDiv>
                <FieldLabel>{i18next.t('PROFILE_FORM:SUB_SECTOR')}</FieldLabel>
                <Field
                  // eslint-disable-next-line
                  component={Select}
                  name="subSector"
                  validate={[profileSelectField]}
                  options={subSectorLabels}
                  onChange={handleChangeSubSector}
                />
              </SelectDiv>
              {renderSubSectorOther && (
                <InputDiv>
                  <Field
                    component={Input}
                    variant="outlined"
                    name="subSectorOtherText"
                    label={i18next.t('PROFILE_FORM:SUB_SECTOR_OTHER')}
                    size="small"
                    validate={[requiredValidation]}
                  />
                </InputDiv>
              )}
              <SelectDiv>
                <FieldLabel>{i18next.t('PROFILE_FORM:COLLABORATORS')}</FieldLabel>
                <Field
                  // eslint-disable-next-line
                  component={Select}
                  name="collaborators"
                  validate={[profileSelectField]}
                  options={numberOfCollaboratorsLabels}
                  disabled={
                    collaborators !==
                    numberOfCollaboratorsLabels[CompanyNumberOfCollaborators.NOT_SPECIFIED]
                  }
                />
              </SelectDiv>
              <SelectDiv>
                <FieldLabel>{i18next.t('PROFILE_FORM:HOW_DID_YOU_HEAR_ABOUT_US')}</FieldLabel>
                <Field
                  // eslint-disable-next-line
                  component={Select}
                  name="hearAboutUs"
                  validate={[profileSelectField]}
                  options={hearAboutUsLabels}
                  disabled={
                    hearAboutUs !== hearAboutUsLabels[CompanyHowDidYouHearAboutUs.NOT_SPECIFIED]
                  }
                  onChange={handleChangeHearAboutUs}
                />
              </SelectDiv>
              {renderHearAboutUsOtherText && (
                <InputDiv>
                  <Field
                    component={Input}
                    variant="outlined"
                    name="hearAboutUsOtherText"
                    label={i18next.t('PROFILE_FORM:HOW_DID_YOU_HEAR_ABOUT_US_OTHER')}
                    size="small"
                    validate={[requiredValidation]}
                    disabled={
                      hearAboutUs !==
                        hearAboutUsLabels[CompanyHowDidYouHearAboutUs.NOT_SPECIFIED] &&
                      hearAboutUs !== hearAboutUsLabels[CompanyHowDidYouHearAboutUs.OTHER]
                    }
                  />
                </InputDiv>
              )}
              <RadioGroupDiv>
                <FieldLabel>{i18next.t('PROFILE_FORM:IS_PROVIDER_OR_CLIENT')}</FieldLabel>
                <Field component={RadioGroup} name="companyRole" validate={[profileCompanyRole]}>
                  <RadioButtonsContainer>
                    <RadioButton
                      label={i18next.t('PROFILE_FORM:CLIENT')}
                      value={CompanyRole.CLIENT.toString()}
                      checked={selectedCompanyRole === CompanyRole.CLIENT}
                      onChange={handleChange}
                      disabled={companyRole !== CompanyRole.NOT_SPECIFIED}
                    />
                    <RadioButton
                      label={i18next.t('PROFILE_FORM:PROVIDER')}
                      value={CompanyRole.PROVIDER.toString()}
                      checked={selectedCompanyRole === CompanyRole.PROVIDER}
                      onChange={handleChange}
                      disabled={companyRole !== CompanyRole.NOT_SPECIFIED}
                    />
                  </RadioButtonsContainer>
                </Field>
              </RadioGroupDiv>
            </>
          )}
          <CheckboxDiv>
            <Field
              component={CustomCheckbox}
              checked={subscribedToReminder}
              variant="outlined"
              name="subscribedToReminder"
              label={i18next.t('PROFILE_FORM:SUBSCRIBED_TO_REMINDER')}
              size="small"
            />
          </CheckboxDiv>
          <CheckboxDiv>
            <Field
              component={CustomCheckbox}
              checked={acceptPromotions}
              variant="outlined"
              name="acceptPromotions"
              label={i18next.t('PROFILE_FORM:ACCEPT_PROMOTIONS')}
              size="small"
            />
          </CheckboxDiv>
        </InputsLeft>
        <InputsRight>
          <Text>{i18next.t('PROFILE_FORM:TEXT')}</Text>
          <InputDiv>
            <Field
              component={Input}
              variant="outlined"
              type="textarea"
              name="emails"
              label="Emails"
              size="small"
              rows={7}
              rowsMax={7}
              maxLength={4096}
            />
          </InputDiv>
          <MailsText>{i18next.t('PROFILE_FORM:MAILS')}</MailsText>
        </InputsRight>
      </RowDiv>
      <ButtonContainer>
        <FormButton disabled={submitting} type="submit">
          Guardar
        </FormButton>
      </ButtonContainer>
    </Form>
  );
};

export default reduxForm<IProfileFormData, IProfileFormProps>({
  form: 'profile',
  enableReinitialize: true,
})(ProfileForm);
