import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import useTypedSelector from 'hooks/useTypedSelector';

import LayoutContainer from 'containers/LayoutContainer';
import ProfileView from 'views/ProfileView';
import { IProfileFormData } from 'views/ProfileView/types';
import { onLogout, onUpdate } from 'redux/actions/user.actions';
import { companySectors } from 'helpers/enums/companySectors';
import { companyNumberOfCollaborators } from 'helpers/enums/companyNumberOfCollaborators';
import { companyHowDidYouHearAboutUs } from 'helpers/enums/companyHowDidYouHearAboutUs';
import { companySubSectors } from 'helpers/enums/companySubSector';

const ProfileContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const company = useTypedSelector((state) => state.company.data);
  const user = useTypedSelector((state) => state.company.user);

  const onSubmit = (formData: IProfileFormData) => dispatch(onUpdate(formData));

  const findLabelByValue = (array: Array<any>, value: number) => {
    return array.find((item) => item.value === value)?.label || '';
  };

  const companyFormData = {
    name: company?.name || '',
    cuit: company?.cuit || '',
    email: user?.email || '',
    phone: company?.phone || '',
    emails: company?.emails || '',
    subscribedToReminder: user?.subscribedToReminder || false,
    acceptPromotions: user?.acceptPromotions || false,
    sector: company ? findLabelByValue(companySectors, company.sector) : '',
    specialty: company?.specialty || '',
    collaborators: company
      ? findLabelByValue(companyNumberOfCollaborators, company.collaborators)
      : '',
    hearAboutUs: company ? companyHowDidYouHearAboutUs[company.hearAboutUs].label : '',
    hearAboutUsOtherText: company?.hearAboutUsOtherText || '',
    companyRole: company?.companyRole,
    state: company?.state || '',
    subSector: company ? findLabelByValue(companySubSectors, company.subSector) : '',
    subSectorOtherText: company?.subSectorOtherText || '',
  };
  const onLogoutClick = () => {
    dispatch(onLogout());
  };

  return (
    <LayoutContainer>
      {!!company && !!user && (
        <ProfileView
          onSubmit={onSubmit}
          initialValues={companyFormData}
          onLogoutClick={onLogoutClick}
          country={company.country}
          user={user}
        />
      )}
    </LayoutContainer>
  );
};

export default ProfileContainer;
