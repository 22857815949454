import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { codeIsSubmitted, howManyPoints } from 'helpers/codeValidations';
import useTypedSelector from 'hooks/useTypedSelector';
import {
  onCloseCertModal,
  onSubmitTrainingCode,
  onMarkTrainingComplete,
  onGetOneTraining,
} from 'redux/actions/training.action';
import { ISubmitCodeData } from 'views/TrainingDetailView/types';
import { onCompleteForms, onGetForms } from 'redux/actions/user.actions';
import Modal from 'components/commons/Modal';
import Loading from 'components/commons/Loading';

import LayoutContainer from 'containers/LayoutContainer';
import TrainingDetailView from 'views/TrainingDetailView';
import ErrorView from 'views/ErrorView';
import { getFormQuestionsAndAnswers } from 'helpers/forms';
import { IForm } from 'types/form.types';

const TrainingDetailContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const company = useTypedSelector((state) => state.company.data);
  const user = useTypedSelector((state) => state.company.user);
  const forms = useTypedSelector((state) => state.company.user?.forms);
  const { trainingId, behaviour } = useParams<{ trainingId: string; behaviour: string }>();
  const showCertModal = useTypedSelector((state) => state.trainings.showCertModal);
  const closeCertModal = () => dispatch(onCloseCertModal());
  const currentTraining = useTypedSelector((state) => state.trainings.current);
  const currentTrainingLoading = useTypedSelector((state) => state.trainings.currentLoading);
  const capsules = useTypedSelector((state) => state.capsules.items);
  const currentCapsuleName = capsules.find(
    (capsule) => capsule.id === currentTraining?.capsuleId,
  )?.title;
  const trainingLoading = useTypedSelector((state) => state.trainings.loading);

  useEffect(() => {
    if (!forms) dispatch(onGetForms());
    if (trainingId !== currentTraining?.id) dispatch(onGetOneTraining(trainingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const codeSubmitted =
    currentTraining && company && user && codeIsSubmitted(currentTraining.completedTrainings, user);

  const onSubmit = (formData: ISubmitCodeData) => dispatch(onSubmitTrainingCode(formData));

  const onMarkAsCompleted = () =>
    !codeSubmitted ? dispatch(onMarkTrainingComplete(trainingId)) : {};

  const points =
    codeSubmitted && currentTraining && user
      ? howManyPoints(currentTraining.completedTrainings, user)
      : 0;

  const completeForm = (form: IForm, values: any) => {
    const completedFormData = {
      formId: form.id,
      formAnswers: getFormQuestionsAndAnswers(form, values),
    };
    dispatch(onCompleteForms(completedFormData));
  };

  return !trainingLoading && !currentTrainingLoading && company && user ? (
    <>
      {currentTraining && (
        <LayoutContainer>
          <TrainingDetailView
            completeForm={completeForm}
            onSubmit={onSubmit}
            onMarkAsCompleted={onMarkAsCompleted}
            training={currentTraining}
            codeSubmitted={!!codeSubmitted}
            points={points}
            userCountry={user.country}
            showCertModal={showCertModal}
            closeCertModal={closeCertModal}
            forms={forms}
            openWithVideo={behaviour === 'open'}
            currentCapsuleName={currentCapsuleName}
          />
        </LayoutContainer>
      )}
      {!currentTraining && <ErrorView />}
    </>
  ) : (
    <LayoutContainer>
      <Modal disabled isOpen>
        <Loading />
      </Modal>
    </LayoutContainer>
  );
};

export default TrainingDetailContainer;
